import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [

    // 默认进入主页
    {
        path: "/",
        component: () => import('../views/Home.vue'),
        redirect: '/Home',
    },
    {
        // 默认进入登录页
        path: '/Home',
        name: 'Home',
        component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue'),
        children: [{
            path: '/',
            redirect: 'index',
        }, {
            path: 'index',
            name: '主页',
            component: () => import('../views/index.vue'),
        }, {
            path: '/productpresentation',
            name: '商贸进销存',
            component: () => import('../views/productpresentation/commerceandtrade.vue'),
        }, {
            path: "/clothing",
            component: () => import('../views/productpresentation/clothing.vue'),
        }, {
            path: "/Metalbuildingmaterials",
            component: () => import('../views/productpresentation/Metalbuildingmaterials.vue'),
        }, {
            path: "/homeappliances",
            component: () => import('../views/productpresentation/homeappliances.vue'),
        }, {
            path: "/OA",
            component: () => import('../views/OA/OaIndex.vue'),
        }, {
            path: "/OAMODE",
            component: () => import('../views/OA/oaMODE.vue'),
        }, {
            path: "/Handbook",
            component: () => import('../views/Handbook.vue'),
        }, {
            path: "/HanBookVideo",
            component: () => import('../views/HanBookVideo.vue'),
        }, {
            path: "/beautyindustry",
            component: () => import('../views/Storemanagement/beautyindustry.vue'),
        }, {
            path: "/amusementpark",
            component: () => import('../views/Storemanagement/amusementpark.vue'),
        }, {
            path: "/chessandcard",
            component: () => import('../views/Storemanagement/chessandcard.vue'),
        }, {
            path: "/Theautoparts",
            component: () => import('../views/Storemanagement/Theautoparts.vue'),
        }, {
            path: "/Businesssuperretail",
            component: () => import('../views/Storemanagement/Businesssuperretail.vue'),
        }, {
            path: "/medicaltreatment",
            component: () => import('../views/Intelligentrepairservice/medicaltreatment.vue'),
        }, {
            path: "/case",
            component: () => import('../views/case.vue'),
        }, {
            path: "/downAPP",
            component: () => import('../views/downAPP.vue'),
        }, {
            path: "/aboutUs",
            component: () => import('../views/aboutUs.vue'),
        }, {
            path: "/wx",
            component: () => import('../views/PHONE/wx.vue'),
        }, {
            path: "/BX",
            component: () => import('../views/PHONE/BX.vue'),
        }, {
            path: "/phOA",
            component: () => import('../views/PHONE/phOA.vue'),
        }, {
            path: "/gj",
            component: () => import('../views/PHONE/gj.vue'),
        }, {
            path: "/PhoneStore",
            component: () => import('../views/store/PhoneStore.vue'),
        }, {
            path: "/phonedatail",
            component: () => import('../views/store/phonedatail.vue'),
        }, {
            path: "/cooperation",
            component: () => import('../views/cooperation.vue'),
        },
            {
                path: "/LawFirm/LawFirm",
                name: '律所oa介绍',
                component: () => import('../views/LawFirm/LawFirm'),
            },
            {
                path: "/ERP/introduce",
                name: 'ERP系统介绍',
                component: () => import('../views/ERP/introduce'),
            },
            {
                path: "/Agent/Agent",
                name: '代理商家',
                component: () => import('../views/Agent/Agent'),
            }


        ]
    },
    {
        path: "/AdminLogin",
        component: () => import('../views/AdminLogin.vue'),
    },
    {
        path: "/Alipay",
        component: () => import('../views/store/Alipay.vue'),
    },
    {
        path: "/invoice",
        component: () => import('../views/invoice.vue'),
    },
    {
        path: "/certificate",
        component: () => import('../views/certificate/businesslicense.vue'),
    },
    {
        path: "/qualification",
        component: () => import('../views/certificate/qualification.vue'),
    },

    {
        path: "/store",
        component: () => import('../views/store/index.vue'),
    },
    {
        path: "/information",
        component: () => import('../views/store/information.vue'),
    },
    {
        path: "/msgdta",
        component: () => import('../views/OA/msgdta.vue'),
    },
    {
        path: "/Setinghelp",
        component: () => import('../views/OA/Setinghelp.vue'),
    },
    {
        path: "/personnel",
        component: () => import('../views/OA/personnel.vue'),
    },
    {
        path: "/finance",
        component: () => import('../views/OA/finance.vue'),
    },
    {
        path: "/administrative",
        component: () => import('../views/OA/administrative.vue'),
    },
    {
        path: "/target",
        component: () => import('../views/OA/target.vue'),
    },
    {
        path: "/clientrelations",
        component: () => import('../views/OA/clientrelations.vue'),
    },
    {
        path: "/PM",
        component: () => import('../views/OA/PM.vue'),
    },
    {
        path: "/IERP",
        component: () => import('../views/OA/IERP.vue'),
    },
    {
        path: "/Mydesktop",
        component: () => import('../views/OA/Mydesktop.vue'),
    },
    {
        path: "/MORE",
        component: () => import('../views/OA/MORE.vue'),
    },
    {
        path: "/Myaffairs",
        component: () => import('../views/OA/Myaffairs.vue'),
    },
    {
        path: "/1",
        component: () => import('../views/store/index.vue'),
    },
    {
        path: "/2",
        component: () => import('../views/store/index.vue'),
    },
    {
        path: "/3",
        component: () => import('../views/store/index.vue'),
    },
    {
        path: "/4",
        component: () => import('../views/store/index.vue'),
    },
    {
        path: "/productdetails",
        component: () => import('../views/store/productdetails.vue'),
    }, {
        path: "/Success",
        component: () => import('../views/store/Success.vue'),
    }, {
        path: "/WeChat",
        component: () => import('../views/WeChat/binding.vue'),
    },
    {
        path: "/phonePay",
        component: () => import('../views/store/phonePay.vue'),
    },
    {
        path: "/merit",
        component: () => import('../views/merit.vue'),
    },{
        path: "/transfer",
        component: () => import('../views/transfer.vue'),
    },{
        path: "/TiktokAuthorization",
        component: () => import('../views/Authorization/TiktokAuthorization.vue'),
    },{
        path: "/Tiktok",
        component: () => import('../views/Authorization/Tiktok.vue'),
    }


];

const router = new VueRouter({
    routes
});
router.beforeEach(function (to, from, next) {
    next()
    // console.log(store.state.Plogin.ID);
    // if(store.state.Plogin.ID || to.path=='/register'){
    // 	console.log(123);
    // 	next()
    // }else{
    // if(to.name!=='Login'){
    // 	console.log(123);
    // 	next({ path: '/Login',
    // 	query: to.query
    // 	})
    // }else{
    // 	console.log(to,'来');
    // 	console.log(from,'去');
    // 	next()

    // }
    // // next()
    // }
    // console.log(this.$store.state.Plogin);
    // if (to.meta.needLogin) {
    //   //页面是否登录
    //   if (localStorage.getItem("token")) {
    // 	//本地存储中是否有token(uid)数据
    // 	next(); //表示已经登录
    //   } else {
    // 	//next可以传递一个路由对象作为参数 表示需要跳转到的页面
    // 	next({
    // 	  name: "login"
    // 	});
    //   }
    // } else {
    //   //表示不需要登录
    //   next(); //继续往后走
    // }
});

export default router;
